/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

/* .dark-reader-bg {
  background-color: rgb(38, 41, 43);
} */

footer {
  /* position: relative;
  left: 0;
  bottom: 0;
  /* width: 100%; */
  /* height: 100%; */
  text-align: center;
  color: black; 
  display: table-footer-group;
}

.content {
  height: 100%;
  display: table-row-group;
}

code {
  color: #e01a76;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .table-list-group button h4, .h6 {
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
}


body {
  /* color: rgb(240, 236, 229); */
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* background-color: rgb(38, 41, 43); */
  min-height: 100%;
  min-width: 100%;
  display: table;
}

html {
  height: 100%;
}

/* Fade in */

.fade-in-image  {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}


/* Override bootstrap styles for auth forms */

.card .card-header h2 {
    color: #fff;
}

.card {
    border: 0px;
    border-radius: 20px !important;
}

.card-header {
    /* background-color: darkslategrey; */
    border-bottom: none;
    border-radius: 20px 20px 0px 0px !important;
}

.idp-title {
    border-left: 5px solid green;
}

.pb-6 {
    padding-bottom: 100px;
}


 /* Custom input style */
 
.form-control:not([type="file"]) {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    padding-left: .25rem;
}

.form-control:focus {
  border-radius: .25rem;
}

.form-control{
    padding-left: .25rem;
    color: azure !important;
    background-color: transparent !important;
}
.custom-file-input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.form-control::placeholder {
    opacity: 0.6;
    padding-bottom: .25rem;
}

.form-control.is-invalid {
    /* border-color: rgb(252, 201, 201); */
}

.form-control:focus {
    box-shadow: 0 4px 2px -2px rgba(0, 123, 255, .25);
}

.form-group label {
    margin-bottom: 0;
}

.custom-file.is-invalid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.1em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #79322d !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

